<template>
  <div class="tenant-detail">
    <el-form ref="form" :model="formData" :validate-on-rule-change="false" v-loading="isShowLoading"
      :disabled="isDisabled">
      <div v-for="item in formList"
        :class="{'item-box-50': item.isCol50, 'item-box-33': item.isCol33, 'item-box-def': !item.isCol50&&item.isCol33, 'item-hidden': item.isHidden}"
        :key="item.key">
        <el-form-item :label="item.label">
          <div v-if="item.type == 'subTitle'" class="form-item_subTitle">{{item.subLabel}}</div>
          <el-input v-else v-model="formData[item.key]" clearable :class="{'item-content-67': item.isWidth67}">
          </el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        type: "",
        contractId: "",
        isDisabled: true,
        formData: {},
        formList: [{
            label: "姓名",
            key: "realName",
            isCol50: true
          },
          {
            label: "性别",
            key: "genderStr",
            isCol50: true,
            type: "select",
            opt: {
              list: [
                {
                  value: 1,
                  label: "男"
                },
                {
                  value: 2,
                  label: "女"
                }
              ]
            }
          },
          // {
          //   label: "民族",
          //   key: "mz",
          //   isCol50: true
          // },
           {
            label: "手机号码",
            key: "phoneNum",
            isCol50: true
          },
          {
            label: "出生年月",
            key: "birthday",
            isCol50: true
          },
         
          //空出
          // {
          //   label: "",
          //   key: "key1",
          //   type: 'subTitle',
          //   isCol50: true
          // },
          {
            label: "证件类型",
            key: "cardType",
            isCol50: true
          },
          {
            label: "证件号码",
            key: "idCardNoStr",
            isCol50: true
          },
          {
            label: "其他证件类型",
            key: "otherCardType",
            isCol50: true
          },
          {
            label: "其他证件号码",
            key: "otherCardNoStr",
            isCol50: true
          },
          {
            label: "是否注册门禁（R20）",
            key: "isRegisterEntranceGuardStr",
            isCol50: true
          },
          {
            label: "车牌号码",
            key: "carNo",
            isCol50: true
          },
          {
            label: "现居城市",
            key: "city",
            isCol50: true
          },
          {
            label: "公钥密码",
            key: "isExistPublicKeyStr",
            isCol50: true
          },
          {
            label: "详细地址",
            key: "address",
            isCol100: true
          },
          {
            label: "用户来源",
            key: "resourceTypeStr",
            isCol50: true
          },
          {
            label: "用户类型",
            key: "tenantAccountTypeStr",
            isCol50: true
          },
          {
            label: "账号",
            key: "username",
            isCol50: true
          },
          {
            label: "注册时间",
            key: "createdDate",
            isCol50: true
          },
          {
            label: "是否绑定微信",
            key: "isBindWXStr",
            isCol50: true
          },
          {
            label: "认证时间",
            key: "verifiedDate",
            isCol50: true
          },
          {
            label: "账号状态",
            key: "userstateStr",
            isCol50: true
          },
          {
            label: "注销时间",
            key: "updateDateStr",
            isCol50: true
          },
        ],
        isShowLoading: true,
        userId:'',
      };
    },
    created() {
    },
    activated() {
      console.log("tenant-detail created!!");
      this.userId = this.$route.params.id;
      this.getData();

    },
    methods: {
      getData() {
        let _this = this;
        let url ="user-service/user/config/query/apartment-info/"+_this.userId;
        _this.post(url).then(data => {
          // 保存租赁周期
          if (_this.cache.getLS("userInfo")["roleType"] != "8") {
            if(data.idType==1){
              data.idCardNo = data.idCardNo ?
              data.idCardNo.substring(0, 2) +
              "******" +
              data.idCardNo.substring(
                data.idCardNo.length - 2,
                data.idCardNo.length
              ) :
              "";
            }
            data.phoneNum = data.phoneNum ?
              data.phoneNum.substring(0, 3) +
              "****" +
              data.phoneNum.substring(7, data.phoneNum.length) :
              "";
          } else {
            data.idCardNo = data.idCardNo;
          }
          if(data.idType==1 && data.idCardNo){
            data.cardType=this.certTypeArr[data.idType];
            data.idCardNoStr=data.idCardNo;
          }else if(data.idType && data.idType!=1) { //除身份证之外的其他证件
            data.otherCardType = this.certTypeArr[data.idType];
            data.otherCardNoStr= data.idCardNo;
          }
          // else if(!data.idType){ 
            // if(data.accounts&&data.accounts.length > 0) {
            //   let array = [];
            //   array = data.accounts[0];
            //   data.otherCardType = this.certTypeArr[this.cardStrToNum(array.category)];
            //   data.otherCardNoStr= array.account;
            // }
              // this.certTypeOptions.forEach(element => {
              //   if(data.accounts){
              //     data.accounts.forEach(it=>{
              //       if(element.category==it.category){
              //         data.otherCardType=element.label;
              //         if(it.value=='7'){
              //           data.otherCardNoStr=it.account.substring(14, it.account.length)
              //         }
              //         else{
              //           data.otherCardNoStr=it.account;
              //         }
              //         return
              //       }
              //     })
              //     }
              // });
          // }
            data.genderStr = data.gender ? ["男", "女"][data.gender - 1] : '';
            data.isRegisterEntranceGuardStr=data.isRegisterEntranceGuard?'是':'否';
            data.isExistPublicKeyStr=data.isExistPublicKey?'是':'否';
            data.isBindWXStr=data.isBindWX?'是':'否';
            data.resourceTypeStr=data.resourceType?this.userFromArr[data.resourceType]:'小程序注册';
            data.tenantAccountTypeStr= data.authState!=null ? ['实名用户', '实名用户', '实名用户','普通用户'][data.authState] : '普通用户';
            data.userstateStr=["正常","注销中","已注销"][data.cancelState];
            this.myFormList = [];
            for (let index in this.formList) {
              this.myFormList.push(this.formList[index]["key"])
            }
            if(data.cancelState == 2) {
              data.updateDateStr = data.updateDate
              if(this.myFormList.indexOf("updateDateStr") == -1) {
                this.formList.forEach((item,index) => {
                  if(item.key == 'userstateStr') {
                    this.formList.splice(index + 1,0,{
                      label: "注销时间",
                      key: "updateDateStr",
                      isCol50: true
                    })
                  }
                })
              }
            }else{
              data.updateDateStr = '--'
              this.formList.forEach((item,index) => {
                if(item.key == "updateDateStr") {
                  this.formList.splice(index,1)
                }
              })
            }
          _this.formData = data;
          _this.isShowLoading = false;
        });
      }
    }
  };
</script>
<style lang="scss">
  .tenant-detail {
    max-width: 1400px;
    .el-form {
      margin: 0 auto;

      .el-form-item {
        display: flex;
        justify-content: flex-start;
      }

      .el-form-item::before,
      .el-form-item::after {
        display: none !important;
      }


      .el-form-item__label {
        flex: 0.148;
      }

      .el-form-item__content {
        margin: 0;
        flex: 1;
      }

      .item-box-50 {
        display: inline-block;
        width: 50%;

        .el-form-item__label {
          flex: 0.35;
        }
      }

      .item-box-33 {
        display: inline-block;
        width: 33%;

        .el-form-item__label {
          flex: none;
          width: 100px;
        }
      }

      .item-content-67 {
        width: 67%;
      }

      .item-content-100 {
        width: 100%;
      }

      .item-box {
        width: 100%;
      }

      .item-hidden {
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
      }
    }
  }
</style>
<style lang="scss" scoped>
  .app-list {
    height: 590px !important;
  }
</style>