<template>
  <div class="tenant-detail">
    <el-table :data="optList" style="width: 100%;overflow-y: auto; margin-bottom: 15px;" height="630px"
      v-loading="isShowLoading">
      <el-table-column v-for="(item,idx) in optList1" :key="idx" :prop="item.prop" :label="item.label" :on="item.on">
        <template slot-scope="scope">
          <a class="row-nav"
            v-if="item.type=='action' && ((item.key=='order' && scope.row['showOrderLook']) || (item.key=='contract' && scope.row['showContractLook']) || item.key=='roommate')"
            @click="item.on(scope.row)">查看</a>
          <!-- 短租不显示查看合同 -->
          <span
            v-else-if="item.type=='action' && ((item.key=='order' && !scope.row['showOrderLook'])||(item.key=='contract' && !scope.row['showContractLook']) )">--</span>
          <div class="row-action-box" v-else-if="item.type=='actions'">
            <!-- 隐藏显示的逻辑 -->
            <span class="row-action-but" v-for="(action, indexs) in item.opt.list" :key="indexs"
              @click="action.on(scope.row)" v-show="scope.row[item.key][indexs]">{{action.label}}</span>
            <!-- 显示的逻辑置灰 -->
            <!-- <span v-for="(action, indexs) in item.opt.list" :key="indexs">
                <span
                  class="row-action-but"
                  v-if="scope.row[item.key][indexs]==true"
                  @click="action.on(scope.row)"
                >{{action.label}}</span>
                <span
                  class="row-action-baclk"
                  v-else-if="scope.row[item.key][indexs]==false"
                >{{action.label}}</span>
              </span>-->
          </div>
          <span v-else>{{scope.row[item.prop]}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
      @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[5, 10, 15, 20, 25]"
      :page-size="limit" :total="total"></el-pagination>
    <el-dialog title="订单信息" :visible.sync="dialogLookNews0" width="1300px" :close-on-click-modal="false">
      <div class="alertTilte">
        <el-table :data="orderDataList" style="width: 100%">
          <el-table-column v-for="item in column1" :key="item.prop" :prop="item.prop" :label="item.label"
            :width="item.width"></el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="合同信息" :visible.sync="dialogLookNews3" width="1300px" :close-on-click-modal="false">
      <div class="alertTilte">
        <el-table :data="payList" style="width: 100%">
          <el-table-column v-for="item in column3" :key="item.prop" :prop="item.prop" :label="item.label" :on="item.on">
            <template slot-scope="scope">
              <a class="row-nav row-nav-more" v-if="item.on&& scope.row.contractType ==2"
                @click="item.on(scope.row)">查看</a>
              <span v-else>{{scope.row[item.prop]}}</span>
              <!-- <a
                class="row-nav row-nav-more"
                v-if="item.on && ['0','2','3','4', '5'].indexOf(scope.row.state) == -1"
                @click="onTermination(scope.row)"
              >解约</a> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog title="同住人信息" :visible.sync="dialogLookNews4" width="1300px" :close-on-click-modal="false">
      <div class="alertTilte">
        <el-table :data="cohabitantList" style="width: 100%">
          <el-table-column v-for="item in column4" :key="item.prop" :prop="item.prop" :label="item.label">
            <template slot-scope="scope">
              <a class="row-nav row-nav-more" v-if="item.on && isShowCohabitantDelBtn && isCohabit != 2 && state==1 && leaseFrom==2" 
                @click="item.on(scope.row)">删除同住人</a>
              <span v-else>{{scope.row[item.prop]}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt />
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        isShowCohabitantDelBtn: false,
        isShowLoading: true,
        isCohabit: '', //是否是同住人
        state: '', //租房状态
        leaseFrom: '',//租约类型 1-自订；2-排房
        roomId: '',
        id: "",
        type: "",
        contractId: "",
        userType: "",
        actualTitle: "未认证",
        userId: "",
        actualList: [{
            label: "姓名",
            key: "realname",
            value: ""
          },
          {
            label: "性别",
            key: "gender",
            value: ""
          },
          {
            label: "身份证号",
            key: "idcardNoStr",
            value: ""
          },
          {
            label: "手机号",
            key: "phoneNum",
            value: ""
          }
        ],
        optList: [],
        optListUser: "",
        dialogLookNews0: false,
        dialogLookNews1: false,
        dialogLookNews2: false,
        dialogLookNews3: false,
        dialogLookNews4: false,
        optList1: [
          {
            prop: "orderName",
            label: "套间信息"
          },
          {
            prop: "reservationTypeStr",
            label: "租约类型"
          },
          {
            prop: "leaseTerm",
            label: "租期",
          },
          {
            label: "订单",
            prop: "查看",
            key: "order",
            type: 'action',
            on: (row, index) => {
              _this.dialogLookNews0 = true;
              if (row.leaseType == 0||row.leaseType == 2) {
                _this.column1 = _this.longOrderColumn;
              } else {
                _this.column1 = _this.shortOrderColumn;
              }
              // userType为1 admin  userType为8 公
              _this.getOrdeData(row);
            }
          },
          // {
          //   label: "缴费信息", 
          //   prop: "查看",
          //   key: "action",
          //   on: (row, index) => {
          //     console.log(row);
          //     _this.dialogLookNews2 = true;
          //     _this.getTenantDetail(row.roomId);
          //   }
          // },
          {
            label: "合同",
            prop: "--",
            key: "contract",
            type: 'action',
            on: (row, index) => {
              _this.dialogLookNews3 = true;
              _this.getData(row);
            }
          },
          {
            label: "同住人",
            prop: "查看",
            key: "roommate",
            type: 'action',
            on: (row, index) => {
              _this.contractType = row.contractType;
              _this.isCohabit = row.isCohabit;
              _this.roomId = row.roomId;
              _this.id = row.id;
              _this.state = row.state;
              _this.getCohabitantData(row);
              _this.dialogLookNews4 = true;
              _this.leaseFrom = row.leaseFrom;
            }
          },
          {
            prop: "leaseState",
            label: "入住状态"
          },
          {
            label: "操作",
            prop: "查看",
            key: "actions",
            type: "actions",
            opt: {
              list: [{
                label: "查看租约",
                on(row) {
                  _this.$router.push({
                    path: "/main/leaseManagement/detailTab/show/" + row.leaseContractId,
                    query: {
                      roomId: row.roomId,
                      leaseType: row.leaseType,
                      leaseFrom: row.leaseFrom,
                      leaseId: row.leaseId,
                      // isPlan: row.isPlan,
                      userId: _this.userId,
                      state: row.state,
                      startDate: row.startDate,
                      entrance: 2,//1-租约列表  2-房客列表
                      validContractId: row.validContractId,
                    }
                  });
                  // _this.goTermination(row);
                }
              }, ]
            }
          }
        ],
        column1: [],
        shortOrderColumn: [{
            prop: "orderNo",
            label: "订单编号"
          },
          {
            prop: "startTime",
            label: "入住时间"
          },
          {
            prop: "endTime",
            label: "截止时间"
          },
          {
            prop: "hours",
            label: "入住时长"
          },
          {
            prop: "payDate",
            label: "实缴时间"
          },
          // {
          //   prop: "userName",
          //   label: "缴费人（角色）"
          // },
          {
            prop: "orderName",
            label: "订单名称"
          },
          {
            prop: "rentalFee",
            label: "租金",
            width: 80
          },
          {
            prop: "depositFee",
            label: "押金",
            width: 80
          },
          {
            prop: "orderAmount",
            label: "总金额",
            width: 100
          },
          {
            prop: "payState",
            label: "订单状态",
            width: 80
          }
        ],
        longOrderColumn: [{
            prop: "orderNo",
            label: "订单编号"
          },
          {
            prop: "payRentalTerm",
            label: "应缴日期"
          },
          {
            prop: "payDate",
            label: "实缴日期"
          },
          {
            prop: "userName",
            label: "缴费人（角色）"
          },
          {
            prop: "orderName",
            label: "订单名称"
          },
          {
            prop: "rentalFee",
            label: "租金",
            width: 80
          },
          {
            prop: "depositFee",
            label: "押金",
            width: 80
          },
          {
            prop: "otherFee",
            label: "物业费",
            width: 80
          },
          {
            prop: "electric",
            label: "电费",
            width: 80
          },
          {
            prop: "coldWater",
            label: "冷水费",
            width: 80
          },
          {
            prop: "hotWater",
            label: "热水费",
            width: 80
          },
          {
            prop: "orderAmount",
            label: "总金额",
            width: 100
          },
          {
            prop: "payState",
            label: "订单状态",
            width: 80
          }
        ],
        payList: [],
        originalColumn2: [{
            prop: "startDate",
            label: "起租日期"
          },
          {
            prop: "endDate",
            label: "到期日期"
          },
          {
            prop: "reconDate",
            label: "账单生成日期"
          },
          {
            prop: "payRentalTerm",
            label: "收租日期"
          },
          {
            prop: "rentalFee",
            label: "租金"
          },
          {
            prop: "deposit",
            label: "押金"
          }
        ],
        column3: [{
            prop: "contractTypeStr",
            label: "租赁合同类型"
          },
          {
            prop: "createdDate",
            label: "签约时间"
          },
          {
            prop: "id",
            label: "合同编号"
          },
          // {
          //   prop: "apartmentName",
          //   label: "房屋详情"
          // },
          {
            prop: "tenancyTerm",
            label: "租期",
          },
          // {
          //   prop: "endDateStr",
          //   label: "到期日期"
          // },
          {
            prop: "contractDate",
            label: "合同起止日期"
          },
          {
            prop: "rentalFee",
            label: "签约租金"
          },
          {
            prop: "deposit",
            label: "签约押金"
          },
          {
            prop: "propertyFee",
            label: "签约物业费"
          },
          {
            prop: "stateStr",
            label: "合同状态"
          },
          {
            label: "操作",
            prop: "查看",
            key: "actions",
            type: "actions",
            on: (row, index) => {
              _this.dialogLookNews3 = false;
              if (row["downloadUrl"]) {
                var tempwindow = window.open('_blank');
                tempwindow.location = row["downloadUrl"];
                // window.location.href=row["downloadUrl"];
              }
            }
          }
        ],
        column4: [{
            prop: "roomAllName",
            label: "房间信息"
          },
          {
            prop: "cohabitantName",
            label: "同住人姓名"
          },
          {
            prop: "gender",
            label: "性别"
          },
          {
            prop: "cardTypeStr",
            label: "证件类型"
          },
          {
            prop: "idcardNoStr",
            label: "证件号码"
          },
          {
            prop: "phoneNum",
            label: "手机号码"
          },
          {
            prop: "startDate",
            label: "同住起始日期"
          },
          // {
          //   label: "操作",
          //   prop: "查看",
          //   key: "action",
          //   type: "action",
          //   on(row) {
          //     const h = _this.$createElement;
          //     _this
          //       .$msgbox({
          //         title: "删除同住人",
          //         message: h("p", null, [
          //           h(
          //             "span", {
          //               style: "color: #333;font-size: 16px;margin-left:98px"
          //             },
          //             "确定要删除同住人吗？"
          //           ),
          //           h(
          //             "p", {
          //               style: "font-size:14px;margin-left:98px"
          //             },
          //             "请先与房客确认删除同住人"
          //           )
          //         ]),
          //         showCancelButton: true,
          //         confirmButtonText: "确定",
          //         cancelButtonText: "取消"
          //       })
          //       .then(action => {
          //         var dto = {
          //           contractId: row.contractId,
          //           userId: row.userId,
          //         };
          //         _this
          //           .post("/tenant-service/leaseroomuser/delCohabitant", dto)
          //           .then(function () {
          //             _this.$message({
          //               showClose: true,
          //               message: "删除同住人成功!",
          //               type: "success"
          //             });
          //             _this.getCohabitantData(row);

          //           });
          //       });
          //   }
          // }
        ],
        authenticationData: [],
        orderDataList: [],
        cohabitantList: [], // 同住人信息
        leasecycle: "", // 租赁周期
        contractId: "", //合同id
        roomId: "", //房间id
        rooTypeList: [], //房型列表
        facilities: [], //配套标签列表
        apartmentList: [], // 房源列表
        idCardPic1: "", 
        idCardPic2: "",
        dialogVisible: false,
        dialogImageUrl: "",
        carRecordDataList: [], //车辆进出信息
        currentPage: 1,
        skip: 1,
        limit: 10,
        total: 0,
      };
    },
    created() {
      this.userId = this.$route.params.id;
      // this.roomId = this.$route.query.roomid || "";
      this.userType = this.cache.getLS("userInfo")["roleType"];
    },
    activated() {
      console.log("tenant-detail created!!");
      this.userId = this.$route.params.id;
      this.optList = [];
      this.payList = [];
      this.cohabitantList = [];
      this.orderDataList = [];
      // this.roomId = this.$route.query.roomid || "";
      this.userType = this.cache.getLS("userInfo")["roleType"];
      let _this = this;
      //长租显示物业费
      // userType为1 admin  userType为8 公安账号   type为1短租
      // 1.相同角色  长租比短租多合同列   2.公安账号没有操作列，只有admin账号有操作列

      if (this.userType === "8") {
        this.column3 = [{
            prop: "contractTypeStr",
            label: "租赁合同类型"
          },
          {
            prop: "createdDate",
            label: "签约时间"
          },
          {
            prop: "id",
            label: "合同编号"
          },
          // {
          //   prop: "apartmentName",
          //   label: "房屋详情"
          // },
          {
            prop: "count",
            label: "合同期限"
          },
          // {
          //   prop: "endDateStr",
          //   label: "到期日期"
          // },
          {
            prop: "tenancyTerm",
            label: "租期",
          },
          {
            prop: "rentalFee",
            label: "签约租金"
          },
          {
            prop: "deposit",
            label: "签约押金"
          },
          {
            prop: "propertyFee",
            label: "签约物业费"
          },
          {
            prop: "stateStr",
            label: "合同状态"
          },
        ];
        this.column4 = [{
            prop: "roomAllName",
            label: "房间信息"
          },
          {
            prop: "cohabitantName",
            label: "同住人姓名"
          },
          {
            prop: "gender",
            label: "性别"
          },
          {
            prop: "cardTypeStr",
            label: "证件类型"
          },
          {
            prop: "idcardNoStr",
            label: "证件号码"
          },
          {
            prop: "phoneNum",
            label: "手机号码"
          },
          {
            prop: "startDate",
            label: "同住起始日期"
          },
        ];
      }
      _this.onSearch();
      if(_this.getLandlordFlage() || _this.getHousekeeperPlanAndQuitFlage()) {
        _this.isShowCohabitantDelBtn = true;
      }else {
        _this.isShowCohabitantDelBtn = false;
      }
    },
    methods: {
      onSearch() {
        let _this = this;
        _this.isShowLoading = true;
        let dto = {
          current: _this.skip,
          pageSize: _this.limit,
          userId: this.userId
        };
        let url = "/tenant-service/v1/lease-info/query/get-tenant-lease-info";
        _this.post(url, dto, {
          isUseResponse: true,
          timeout: 1000 * 60 * 2,
          // timeout:1000 * 2,
        }).then(res => {
          let data = res.data.data.data;
          data.forEach(item => {
            item.actions = [true]
            if(item.leaseType == 3){
              item.leaseTerm = moment(item.startDate).format('YYYY-MM-DD HH:mm:ss') + '~' + moment(item.checkoutDate).format('HH:mm:ss');
            }else{
              item.leaseTerm = this.format(item.startDate, 1) + '~' + this.format(item.checkoutDate, 1);
            }
            let typeStr1 = ''
            if (item.leaseFrom == 1 ) {
              typeStr1 = '自订/';
              //  if (item.state == 1) {
              //   item.actions = [true]
              //  }
            } else if (item.leaseFrom == 3 ) {
              let typeStr1 = ''
            } else {
              typeStr1 = '排房/';
              //  if (item.state == 1 || item.state == 2) {
              //    item.actions = [true]
              //  }
            }
            if (item.isCohabit == 2) {
              typeStr1 = '同住/';
              // item.actions = [false]
            }
            item.reservationTypeStr = typeStr1 + ['长租', '短租','长租','钟点房','PMS排房'][item.leaseType]
            item.showOrderLook = (item.leaseFrom == 1) ? true : false; //是否显示查看订单，自订
            item.showContractLook = ((item.leaseFrom != 2 && item.leaseFrom !=3)&& (item.leaseType == 0 || item.leaseType == 2)) ? true : false; //是否显示查看合同,自订长租
            if (item.leaseType == 1 && item.state == 1 && moment(new Date()).format('YYYY-MM-DD HH:mm:ss') < (
                moment(item.startDate).format('YYYY-MM-DD HH:mm:ss'))) {
              item.leaseState = '待入住';
              item.state = 2;
            } else {
              item.leaseState = ['已退房', '在租', '已退房'][item.state];
            }
            item.orderName = item.houseName + item.buildingName+ item.roomNo + '号房'

          })
          _this.isShowLoading = false;
          _this.optList = data;
          this.total = res.data.data.total;
        });
      },
      // 获取订单
      getOrdeData(data) {
        let dto = {
          // orderType: data.leaseType == 1 ? 1 : 0,
          // userId: this.userId,
          // roomId: data.roomId,
          // contractId: data.id,
          // sign: 1,
          leaseId: data.leaseId,
        };
        // 房客列表-房客详情-租约信息--订单
        this.post("tenant-service/v1/lease-info/query/get-tenant-order-info", dto).then(
          res => {
            res = res.reverse();
            res.forEach(item => {
              for (var key in item) {
                if (key == "payState") {
                 //1： 未支付 2：支付中 3：支付成功 4：支付失败 5: 订单取消 6：订单失效（首单30分钟未支付）
                  item[key] = [
                    "未支付",
                    "支付中",
                    "已支付",
                    "支付失败",
                    "订单取消",
                    "订单失效",
                    "已支付"
                  ][item.payState - 1];
                }
              }
              if (item.waterElectrics && item.waterElectrics[0]) {
                item.electric = item.waterElectrics[0].electric ?
                  "￥" + item.waterElectrics[0].electric :
                  "";
                item.coldWater = item.waterElectrics[0].coldWater ?
                  "￥" + item.waterElectrics[0].coldWater :
                  "";
                item.hotWater = item.waterElectrics[0].hotWater ?
                  "￥" + item.waterElectrics[0].hotWater :
                  "";
              }
              // 取消订单，默认是房客
              item.userName = item.userName + '(' + ['房客', '房客', '同住人'][item.userType] + ')'

              //计算时间差
              var t1 = new Date(item.startTime);
              var t2 = new Date(item.endTime);
              var hours = t2.getTime() - t1.getTime();
              var leave = hours%(24*3600*1000);
              var h = parseInt(leave/(3600*1000));
              var h_leave = leave%(3600*1000);
              var min = parseInt(h_leave/(60*1000));
              var min_leave = h_leave%(60*1000);
              var sec = parseInt(min_leave/1000);
              // item.hours = t1
              if(h==0 && min==0 && sec==0){
                item.hours = '--'
              }else{
              item.hours = h + '时' + min + '分' +  sec + '秒'
              }
            });

            this.orderDataList = res;
          }
        );
      },
      // 获取认证信息
      getTenantDetail() {
        let _this = this;
        this.post("user-service/tenant/getTenantDetail", {
          userId: this.userId
        }).then(res => {
          this.authenticationData = res;
          if (_this.cache.getLS("userInfo")["roleType"] != "8") {
            res.idcardNoStr = res.idcardNo ?
              res.idcardNo.substring(0, 2) +
              "******" +
              res.idcardNo.substring(res.idcardNo.length - 2, res.idcardNo.length) :
              "";
            res.phoneNum = res.phoneNum ?
              res.phoneNum.substring(0, 3) +
              "****" +
              res.phoneNum.substring(7, res.phoneNum.length) :
              "";
          } else {
            res.idcardNoStr = res.idcardNo;
          }
          let keys = [];
          for (var key in res) {
            keys.push(key);
          }
          this.actualList.forEach(item => {
            if (keys.indexOf(item["key"]) != -1) {
              item.value = res[keys[keys.indexOf(item["key"])]];
            }
            if (item.key == "gender" && item.value) {
              item.value = item.value == 1 ? "男" : "女";
            }
          });
          if (res.idCardPic1) {
            this.idCardPic1 = res.idCardPic1;
          }
          if (res.idCardPic2) {
            this.idCardPic2 = res.idCardPic2;
          }
          if (res.authState == '3') {
            this.actualTitle = "未认证";
          } else {
            this.actualTitle = "已认证";
          }
        });
      },
      goTermination(val) {
        if (val.id && val.leaseType == '1') { //短租
          // this.$router.push("/main/tenant/shortTermination/show?rentId=" + val.id.substring(2, val.id.length));
          this.$router.push("/main/tenant/shortTermination/show?rentId=" + val.id);
        }
        if (val.id && (val.leaseType == '0' || val.leaseType == '2')) {
          this.$router.push("/main/tenant/rowHousesTermination/show?contractId=" + val.id + "&leaseId=" + val.leaseId);
        }
      },
      // 获取合同信息
      getData(val, flag) {
        let dto = {
          // pageNum: 1,
          // pageSize: 9999,
          // userid: this.userId,
          // roomId: val.roomId,
          // contractId: val.childContractId,
          leaseId: val.leaseId
        };
        this.post("tenant-service/v1/lease-info/query/get-tenant-contract-info?leaseId="+val.leaseId).then(res => {
          res.forEach(item => {
            item.stateStr = [
              "待支付",
              "签约成功",
              "签约失败",
              "合同到期",
              "已解约",
              "强制解约"
            ][item.state];
            item.startDate = moment(item.startDate).format("YYYY-MM-DD");
            item.endDate = moment(item.endDate).format("YYYY-MM-DD");
            if (
              item.startDate ===
              moment(item.startDate)
              .startOf("month")
              .format("YYYY-MM-DD")
            ) {
              item.count = moment(item.endDate).diff(item.startDate, "month") + 1;
            } else {
              if (
                moment(item.startDate).format("DD") == "31" &&
                moment(item.endDate).format("DD") == "30"
              ) {
                item.count =
                  moment(item.endDate).diff(item.startDate, "month") + 1;
              } else {
                item.count = moment(item.endDate).diff(item.startDate, "month");
              }
            }
            // item.endDateStr = moment(item.endDate).format("YYYY-MM-DD");
            // item.leaseTermStr = moment(item.startDate).format("YYYY-MM-DD") + '~' + moment(item.endDate).format(
            //   "YYYY-MM-DD");
            for (var key in item) {
              if (key == "payState") {
                item[key] = item.payState == 3 ? "已缴费" : "未缴费";
              }
              if (key == "gender" && item[key]) {
                item[key] = ["男", "女"][item[key] - 1];
              }
              if (key == "apartmentName" && item[key]) {
                item[key] = `${item.city}${item[key]}房源${item.buildNo}${item.roomNo}房`;
              }
            }
            item.contractTypeStr = item.isPreview == 1 ? "线下纸质合同" : "CA电子合同";
          });
          this.payList = res;
          if (flag) {
            this.cache.set("tenantDetailContract", this.payList[0]);
            this.$router.push("/main/tenant/termination/show");
          }
        });
      },
      // 获取同住人信息
      getCohabitantData(row) {
        this.cohabitantList = [];
        let dto = {
          userId: this.userId,
          roomId: this.roomId,
          contractId: row.contractId,
        };
        let url = "tenant-service/leaseroomuser/myCohabitantBack";
        let _this = this;
        this.post(url, dto).then(res => {
          res.forEach(item => {
            for (var key in item) {
              item.roomNo = item.roomInfo.roomNo;
              item.cardTypeStr = (item.cardType && item.idCardNo) ? this.certTypeArr[item.cardType] : '';
              item.idcardNoStr = item.idCardNo;
              if (key == "gender") {
                item[key] = ["男", "女"][item.gender - 1];
              }
            }
          });
          this.cohabitantList = res;


        });
      },
      //放大图片
      showBig(src) {
        this.dialogImageUrl = src;
        this.dialogVisible = true;
      },
      //改变表格展示条数
      handleSizeChange(val) {
        // this.skip = 1;
        this.limit = val;
        this.onSearch();
      },
      //表格翻页
      handleCurrentChange(val) {
        this.skip = val;
        this.onSearch();
      },
      /**
       * 强制解约
       */
      onTermination(row) {
        this.dialogLookNews3 = false;
        this.cache.set("tenantDetailContract", row);
        this.$router.push("/main/tenant/termination/show");
      },
      /**
       * 同意退房
       */
      checkOut(row) {
        let _this = this;
        row.rentalStyle = row.rentalStyle == "长租" ? "0" : "1";
        row.checkoutSum =
          row.checkoutSum == "" || row.checkoutSum == undefined ?
          "0.00" :
          row.checkoutSum;
        _this.$router.push(
          "/main/CheckOut/AddDetail/edit/0" +
          "?realname=" +
          row.realname +
          "&address=" +
          row.address +
          "&quitDate=" +
          row.quitDate +
          "&checkoutSum=" +
          row.checkoutSum +
          "&rentalStyle=" +
          row.rentalStyle +
          "&roomId=" +
          row.roomId +
          "&shortRentId=" +
          row.shortRentId
        );
      },
    }
  };
</script>
<style lang="scss">
  .tenant-detail {
    height: 620px;

    .el-tabs {
      margin: 0 auto;
      margin-top: 10px;
      max-width: 900px;

    }
  }

  .shenfen {
    margin-right: 81px;
    margin-left: 99px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #30BAC1;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
    cursor: pointer;
  }

  .row-nav {
    cursor: pointer;
    color: #30BAC1;
  }

  .row-nav-more {
    margin-left: 10px;
  }

  .tenant-detail_idcardWarrper {
    display: flex;
    justify-content: space-around;
  }

  .tenant-detail_idcard span {
    margin-top: 7px;
    display: block;
    text-align: center;
  }

  .row-action-but {
    color: #30BAC1;
    cursor: pointer;
    margin-right: 10px;
  }
</style>
<style lang="scss" scoped>
  .app-list {
    height: 590px !important;
  }
</style>